<template>
    <default-template
        :title="'温熱・省エネ設備機器ポータルサイト'"
        :is-visible-public="false"
        :is-visible-logout="true"
        :is-visible-pass-update="true"
        :is-visible-jigyosya-login="false"
        :is-house="true"
        :is-visible-administrator-header="true"
    >
        <div class="bl_contents_wrapper">
            <div class="bl_underHeader ly_underHeader">
                <h2 class="el_lv2Heading">共有パスワード{{typeName}}</h2>
            </div>
            <error-list />
            <div class="ly_form_box">
                <app-text
                    name="name"
                    v-model="name"
                    label="機関名"
                    class="formrow"
                    ref="jest-shared-password-name"
                />
                <app-text
                    name="zip_password"
                    v-model="zip_password"
                    label="パスワード"
                    type="password"
                    class="formrow"
                    ref="jest-shared-password-zip-password"
                />
                <app-text
                    name="zip_password_confirmation"
                    v-model="zip_password_confirmation"
                    label="パスワード(確認)"
                    type="password"
                    class="formrow"
                    ref="jest-shared-password-zip-password-confirmation"
                />
                <app-text
                    name="note"
                    v-model="note"
                    label="備考"
                    class="formrow wd_22em"
                    ref="jest-shared-password-note"
                />
            </div>
            <div class="ly_buttonBox">
                <app-button
                    name="キャンセル"
                    @click="back"
                    ref="jest-shared-password-cancel"
                    class="cancel"
                />
                <app-button
                    name="変更"
                    v-if="isEdit"
                    @click="edit"
                    ref="jest-shared-password-edit"
                />
                <app-button
                    name="新規登録"
                    v-if="isCreate"
                    @click="create"
                    ref="jest-shared-password-create"
                />
            </div>
        </div>
    </default-template>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { move, mapComputedProperties } from '@/util'
import ErrorList from '@/components/molecules/ErrorList'

export default {
    name: 'AdminSharedPasswordEdit',
    components:{
        ErrorList,
    },
    computed:{
        ...mapGetters({
            data:'admin/sharedPasswords/sharedPassword',
            brandOrganizations:'admin/organization/brandOrganizations',
        }),
        ...mapComputedProperties([
            'name',
            'zip_password',
            'zip_password_confirmation',
            'note'
        ]),
        typeName(){
            return {
                edit:'変更',
                create:'作成'
            }[this.$route.meta.type]
        },
        isEdit(){
            return this.$route.meta.type === 'edit'
        },
        isCreate(){
            return this.$route.meta.type === 'create'
        },
    },
    methods:{
        ...mapActions({
            update:'admin/sharedPasswords/updateSharedPassword',
            createSharedPassword:'admin/sharedPasswords/create',
            editSharedPassword:'admin/sharedPasswords/edit',
            clearError:'error/clear',
            updateError:'error/update',
        }),
        moveTo(path){
            move(this.$router, path)
        },
        back(){
            this.clearError()
            this.moveTo('/admin/shared_passwords')
        },
        create(){
            this.clearError()
            return this.createSharedPassword()
                .then(response => {
                    if (response.status == 200) {
                        this.updateError(response)
                        this.moveTo('/admin/shared_passwords')
                    } else {
                        this.updateError(response)
                    }
                })
        },
        edit(){
            this.clearError()
            return this.editSharedPassword()
                .then(response => {
                    if (response.status == 200) {
                        this.updateError(response)
                        this.moveTo('/admin/shared_passwords')
                    } else {
                        this.updateError(response)
                    }
                })
        }
    }
}
</script>

<style scoped>
.bl_contents_wrapper{
    min-width: 800px;
    max-width: 800px;
}
.bl_contents_wrapper >>>.ly_form_box{
    width: 100%;
}
.ly_form_box .formrow{
    grid-template-columns: 9em 1fr;
    width: 100%;
}
.formrow.wd_22em >>> input[type="text"]{
    width: 22em;
}
</style>