<template>
    <default-template
        :title="'温熱・省エネ設備機器ポータルサイト'"
        :is-visible-public="false"
        :is-visible-logout="true"
        :is-visible-pass-update="true"
        :is-visible-jigyosya-login="false"
        :is-house="true"
        :is-visible-administrator-header="true"
    >
        <div class="bl_contents_wrapper">
            <div class="bl_underHeader ly_underHeader">
                <h2 class="el_lv2Heading">共有パスワード一覧</h2>
                <app-button
                    name="新規作成"
                    @click="createSharedPassword()"
                    ref="jest-shared-password-create"
                    class="inline"
                />
            </div>
            <error-list class="notice" />
            <p v-if="total != 0">{{total}}件中{{start}}～{{end}}件表示</p>
            <table
                v-if="sharedPasswords.length !== 0"
                key="resultTable"
                ref="jest-result-table"
                class="lists_table"
            >
                <tr>
                    <th>機関名</th>
                    <th>パスワード</th>
                    <th>備考</th>
                    <th></th>
                </tr>
                <tr
                    v-for="item in sharedPasswords"
                    :key="'sharedPasswords' + item.id"
                    :ref="'jest-shared-passwords' + item.id"
                >
                    <td>{{item.name}}</td>
                    <td>{{item.zip_password}}</td>
                    <td>{{item.note}}</td>
                    <td>
                        <app-button
                            name="変更"
                            @click="updateSharedPassword(item)"
                            :ref="'jest-shared-password-update' + item.id"
                            class="inline wd_80px"
                            
                        />
                        <app-button
                            name="削除"
                            class="delete inline wd_80px"
                            @click="deleteSharedPassword(item)"
                            :ref="'jest-shared-password-delete' + item.id"
                        />
                    </td>
                </tr>
            </table>
            <list-pager
                :page="page"
                :page-count="pageCount"
                @page-change="pageChange"
            />
            <p
                v-if="sharedPasswords.length === 0"
                key="resultParagraph"
                ref="jest-result-paragraph"
            >
                共有パスワードがありません
            </p>
        </div>
    </default-template>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { move, mapComputedProperties, pageContentCount } from '@/util'
import ErrorList from '@/components/molecules/ErrorList'
import ListPager from '@/components/molecules/ListPager'

export default {
    name: 'AdminSharedPasswordList',
    components:{
        ErrorList,
        ListPager,
    },
    computed:{
        ...mapGetters({
            sharedPasswords:'admin/sharedPasswords/sharedPasswords',
            data:'admin/sharedPasswords/pageStatus'
        }),
        ...mapComputedProperties([
            'total',
            'page',
        ]),
        /**
         * リストの件数の合計から算出されるリストのページ数を返す
         */
        pageCount(){
            return Math.ceil(this.total / pageContentCount)
        },
        start(){
            return 1 + (this.page - 1) * pageContentCount
        },
        end(){
            const count = this.page * pageContentCount
            return count < this.total ? count : this.total
        }
    },
    async mounted(){
        const loader = this.$loading.show()
        await this.getSharedPasswords()
        loader.hide()
    },
    methods:{
        ...mapActions({
            getSharedPasswords:'admin/sharedPasswords/getSharedPasswords',
            update:'admin/sharedPasswords/update',
            clearError:'error/clear',
        }),
        /**
         * 共有パスワードリストのページの切り替え
         */
        async pageChange(page){
            const loader = this.$loading.show()
            await this.update({page})
            await this.getSharedPasswords()
            loader.hide()
        },
        /**
         * 共有パスワードリストのページの切り替え
         */
        async search(){
            const loader = this.$loading.show()
            await this.update({page:1})
            await this.getSharedPasswords()
            loader.hide()
        },
        moveTo(path){
            move(this.$router, path)
        },
        async createSharedPassword(){
            this.clearError()
            await this.update({sharedPassword:{
                name:null,
                zip_password:null,
                zip_password_confirmation:null,
                note:null,
            }})
            this.moveTo('/admin/shared_passwords/create')
        },
        async updateSharedPassword(item){
            this.clearError()
            await this.update({sharedPassword:item})
            this.moveTo('/admin/shared_passwords/' + item.id + '/edit')
        },
        async deleteSharedPassword(item){
            this.clearError()
            await this.update({sharedPassword:item})
            this.moveTo('/admin/shared_passwords/' + item.id + '/delete')
        },
    }
}
</script>

<style scoped>
.bl_contents_wrapper{
    min-width: 800px;
    max-width: 800px;
}
.lists_table tr td:last-of-type{
    width: 180px;
    text-align: right;
}
.el_button.inline.wd_80px:not(:last-child){
    margin-right: 8px;
}
</style>